// import forList from '@/mixin/forList';
export default {
    name: 'channel-customer-list',
    // mixins: [forList],
    data() {
        const searchTypeEnum = [
            { label: '手机号码', value: 1 },
            { label: '客户编号', value: 2 },
            { label: '客户姓名', value: 3 },
            { label: '录入人员', value: 4 },
        ];
        const phoneCheck = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入手机号'));
            }
            // this.prefixJson
            console.log('this.prefixJson', this.prefixJson);
            const regex = new RegExp(this.prefixJson.validateRules);
            if (!regex.test(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback();
            }
        };
        return {
            searchTypeEnum,
            SP_: {
                pageNum: 1,
                pageSize: 10,
                search_types: 1, //1-客户电话 2-客户code 3-客户名称 4-录入人员
                searchValue: '',
                dateType: 1, //1-录入时间 2-推荐时间
                starttime: '', //开始时间
                endtime: '', //结束时间
                isSell: null, //推荐状态：1-是 0-否
                isDeal: null, //是否成交：1-是 0-否
                state: null, //状态： 1-成功 2-失败 3-异常
            },
            total: 0,
            auth: {},
            tableData: [],

            channelEmpInfo: {
                empCode: '',
                empName: '',
            },
            accountForm: {
                name: '',
                phone: '',
                isSell: 0,
                staffName: '',
                phoneCountryId: '',
            },
            rules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: phoneCheck, trigger: 'blur' },
                ],
            },
            countryList: [{}],
            prefixJson: {
                areaCode: '+86', //区号
                validateRules: '1[3-9][0-9]{9}', //验证规则
                name: '中国', //名称
                phoneTemp: '123', //示例
                phoneNum: 11, //长度
                id: 1, //Id
            },
            showAddDia: false,
            uploadHeaders: {
                // 图片上传的请求头
                token: this.$store.state.token,
            },
            uploadForm: {
                fileList: [],
            },
            uploadForm2: {
                isSell: 0,
            },
            showUploadDia: false,
            //
            showrecommendDia: false,
            multipleSelection: [],
            recommendForm: {
                staffName: '',
            },
            showDetailDia: false,
            followlist: [], //跟进详情
            uploadLoading: false,
        };
    },
    computed: {
        statusOj() {
            return val => {
                let obj = {};
                if (val === '成功') {
                    obj = { type: 'success', text: '成功' };
                } else if (val === '失败') {
                    obj = { type: 'danger', text: '失败' };
                } else if (val === '异常') {
                    obj = { type: 'warning', text: '异常' };
                }
                return obj;
            };
        },
    },
    methods: {
        isRowSelectable(row, index) {
            if (row.isSell === '是' && (row.state === '成功' || row.state === '失败')) {
                return row.disabled;
            }
            return !row.disabled;
        },
        async fetchData() {
            // const regex = /^\+1[-\s]?(?:[2-9][0-9]{2})[-\s]?[2-9][0-9]{2}[-\s]?[0-9]{4}$/;
            // const num = '+1-5873181046';
            // const res = regex.test(num);
            // console.log('正则', res);

            const loading = this.$loading();
            try {
                const data = {
                    ...this.SP_,
                };
                console.log('data', data);
                const res = await this.$axios({
                    url: '/api/channelCustomer/queryList',
                    method: 'post',
                    data,
                });
                if (res.code === 2000) {
                    const list = res.data.List;
                    this.tableData = list;
                    this.total = res.data.count;
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn('获取数据失败', reason);
            }
        },
        fetchCountryList() {
            this.$axios({
                url: '/api/common/queryCountry',
                method: 'post',
            }).then(res => {
                console.log('获取国家列表', res);
                if (res.code === 2000) {
                    this.countryList = res.data.centerCountryList;
                    this.prefixJson = this.countryList[0];
                    this.accountForm.phoneCountryId = this.prefixJson.id;
                } else if (res.code !== 1003) throw res;
            });
        },
        changeCountry() {
            this.prefixJson = this.countryList.find(it => it.id === this.accountForm.phoneCountryId);
            this.accountForm.phone = '';
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
        handleSubmit() {
            this.pageNum = 1;
            this.pageSize = 10;
            this.fetchData();
        },
        handleClear2() {
            console.log('清除');
            this.SP_ = {
                pageNum: 1,
                pageSize: 10,
                search_types: 1, //1-客户电话 2-客户code 3-客户名称 4-录入人员
                searchValue: '',
                dateType: 1, //1-录入时间 2-推荐时间
                starttime: '', //开始时间
                endtime: '', //结束时间
                isSell: null, //推荐状态：1-是 0-否
                isDeal: null, //是否成交：1-是 0-否
                state: null, //状态： 1-成功 2-失败 3-异常
            };
            console.log('清除ww', this.pageNum);
            this.fetchData();
        },
        fetchChannelEmpInfo() {
            this.$axios({
                url: '/api/channelCustomer/getChannelEmpInfo',
                method: 'post',
            }).then(res => {
                if (res.code === 2000) {
                    this.channelEmpInfo = res.data;
                }
            });
        },
        handleAddBtn() {
            this.showAddDia = true;
            this.$nextTick(() => {
                this.$refs.accountForm.resetFields();
            });
        },
        submitAdd() {
            this.$refs.accountForm.validate(valid => {
                if (valid) {
                    // this.showAddDia = false;
                    // this.$message.success('添加成功');
                    // this.fetchData();

                    this.$axios({
                        url: '/api/channelCustomer/saveOneCust',
                        method: 'post',
                        data: {
                            name: this.accountForm.name,
                            phone: this.accountForm.phone,
                            isSell: this.accountForm.isSell,
                            phoneCountryId: this.accountForm.phoneCountryId,
                        },
                    }).then(res => {
                        if (res.code == 2000) {
                            this.$message.success('添加成功');
                            this.showAddDia = false;
                            this.fetchData();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },

        handleImport() {
            this.uploadForm.fileList = [];
            this.uploadForm2.isSell = 0;
            this.showUploadDia = true;
        },
        handleExceed() {
            this.$message({
                message: `最多只能上传1个文件`,
                type: 'error',
            });
        },
        handleRemove(file, fileList) {
            this.uploadForm.fileList = [];
        },
        //
        handleUploadSuccess(res, file) {
            console.log('wqwwqw', res);
            console.log(file);

            if (res.code == 2000) {
                this.uploadLoading = false;
                if (res.data.errorNum > 0) {
                    this.showUploadDia = false;
                    this.$confirm('批量导入客户成功，已自动导出错误数据', '温馨提示', {
                        confirmButtonText: '导出错误数据',
                        cancelButtonText: '关闭',
                        type: 'success',
                    })
                        .then(() => {
                            window.open(this.$baseURL + '/' + res.data.filePathName, 'download');
                        })
                        .catch(() => {
                            setTimeout(() => {
                                this.fetchData();
                            }, 2000);
                        });
                } else {
                    this.$message({
                        message: '批量导入客户成功',
                        type: 'success',
                    });
                    setTimeout(() => {
                        this.showUploadDia = false;
                        this.fetchData();
                    }, 2000);
                }
            } else {
                this.$message({
                    message: res.msg,
                    type: 'error',
                });
                this.uploadLoading = false;
            }
        },
        handleUploadError() {
            this.uploadLoading = false;
        },
        handleFileChange(files) {
            this.uploadForm.fileList = [files];
        },
        downLoadfile() {
            this.$axios({
                url: '/api/channelCustomer/downCustTemplate',
                method: 'post',
            }).then(res => {
                if (res.code === 2000) {
                    window.open(this.$baseURL + '/' + res.data.filePath, 'download');
                }
            });
        },
        submitUpload() {
            // this.$refs.uploadForm.validate(valid => {
            //     if (valid) {
            if (this.uploadForm.fileList.length == 0) {
                return this.$message({
                    message: '请选择上传文件',
                    type: 'warning',
                });
            }
            this.$refs.formUpload.submit();
            this.uploadLoading = true;
        },
        // 批量推荐
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleRecommend() {
            if (!this.multipleSelection.length) {
                this.$message({
                    message: '请选择要推荐的用户',
                    type: 'warning',
                });
                return;
            }

            this.showrecommendDia = true;
        },
        submitRecommend() {
            const ids = this.multipleSelection.map(item => item.id);
            this.$axios({
                url: '/api/channelCustomer/batchPushCust',
                method: 'post',
                data: {
                    ids: ids.join(','),
                },
            }).then(res => {
                if (res.code == 2000) {
                    this.$message({
                        message: `本次推荐成功${res.data.successNum}个客户`,
                        type: 'success',
                    });
                    this.showrecommendDia = false;
                    this.fetchData();
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                    });
                }
            });
        },
        //
        handleDetail(data) {
            if (!data.custCode) {
                this.$message({
                    message: '该用户暂无推荐码',
                    type: 'warning',
                });
                return;
            }
            this.showDetailDia = true;
            this.$axios({
                url: '/api/channelCustomer/getFollowInfo',
                method: 'post',
                data: {
                    id: data.id,
                },
            }).then(res => {
                if (res.code == 2000) {
                    this.followlist = res.data.followlist;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                    });
                }
            });
        },
    },
    created() {
        this.fetchChannelEmpInfo();
        this.fetchData();
        this.fetchCountryList();
        this.auth.recommend = this.$hasAuthFor('/api/channelCustomer/batchPushCust');
        this.auth.add = this.$hasAuthFor('/api/channelCustomer/saveOneCust');
        this.auth.import = this.$hasAuthFor('/api/channelCustomer/uploadCustFile');
    },
};
