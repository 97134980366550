import { render, staticRenderFns } from "./channel-customer-list.vue?vue&type=template&id=434276d5&scoped=true"
import script from "./channel-customer-list.js?vue&type=script&lang=js&external"
export * from "./channel-customer-list.js?vue&type=script&lang=js&external"
import style0 from "./channel-customer-list.vue?vue&type=style&index=0&id=434276d5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434276d5",
  null
  
)

export default component.exports